import { createStore, applyMiddleware } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { composeWithDevTools } from 'redux-devtools-extension';
import hardSet from 'redux-persist/lib/stateReconciler/hardSet';

import rootReducer from './reducers/reducer';

const persistConfig = {
  key: 'root',
  storage,
};
const configureStore = () => {
  let store;

  const isClient = typeof window !== 'undefined';

  if (isClient) {
    const persistConfig = {
      key: 'root',
      storage,
    };

    store = createStore(
      persistReducer(persistConfig, rootReducer),
      composeWithDevTools(applyMiddleware(thunk)),
    );

    store.__PERSISTOR = persistStore(store);
  } else {
    store = createStore(
      rootReducer,
    );
  }

  return store;
};

export default configureStore;
