export const initialState = {
  loggedIn: false,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_LOGGED_IN':
      return {
        ...state,
        loggedIn: action.loggedIn,
      };
    case 'SET_MEETINGS':
      return {
        ...state,
        meetings: [...action.meetings],
      };
    case 'SET_USER_INFO':
      return {
        ...state,
        ...action.user,
      };
    case 'SET_METRICS':
      return {
        ...state,
        aggregateMetrics: action.metrics,
      };
    case 'CLEAR_USER_INFO':
      return {}; // Set the state to an empty object
    default:
      return state;
  }
};

export default rootReducer;
